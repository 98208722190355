import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { GatsbyImage, getImage} from "gatsby-plugin-image";
import { PortableText } from "@portabletext/react";
import SEO from "../components/SEO";

const  AboutContainer = styled.div`
    display: flex;
    height: 100%;
    > div {
        flex: 1;
    }
    @media (max-width: 768px) {
        flex-direction: column;
        > div:nth-child(2) {
           display: none;
        }
    }
`

const ContentContainer = styled.div`
    padding: 2rem;

    @media (max-width: 480px) {
        padding-bottom: 8rem; 
    }

`

export default function AboutPage({ data }) {
    const [text, heading] = data.sanityPages.pageBuilder;
    const imageData = getImage(heading.image.asset);
    console.log(heading)
    return (
        <AboutContainer>
             <SEO
                title="About"
                keywords={[`J. Marcelo Borromeo`, `Filipino writer`, `UEA`, `novel`, `short story`, `fiction`, `non-fiction`]}
                />
            <ContentContainer>
                <h2>{data.sanityPages.title}</h2>
                <PortableText value={text._rawText} />
            </ContentContainer>
            <GatsbyImage image={imageData} alt={heading.heading} />
        </AboutContainer>
    )
}

export const query = graphql`
    query AboutPage {
        sanityPages(title: {eq: "About"}) {
            title
            pageBuilder {
                ... on SanityHero {
                    _key
                    heading
                    _type
                    image {
                      asset {
                        id
                        gatsbyImageData
                      }
                    }
                  }
              ... on SanityTextContent {
                _key
                _type
                _rawText
              }
            }
          }
    }
`;